<template>
  <div>
    <breadcrumb name="Administration" title="Admins" subtitle="Add Admin" @onTitleClicked="$emit('cancel')"/>
    <vx-card title="User Information" class="mb-base">
      <vs-row vs-type="flex">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="4"
          vs-sm="12"
        >

          <table>
            <tbody>
            <tr>
              <td>
                <span class="font-semibold p-4">Name</span>
              </td>
              <td>
                <span class="p-4">
                  <vs-input
                    v-model="name"
                    type="text"
                    :danger="invalidName"
                    danger-text="Name field can't be empty!"/>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span class="font-semibold p-4">E-mail</span>
              </td>
              <td>
                <span class="p-4">
                  <vs-input
                    v-model="email"
                    type="email"
                    :danger="invalidEmail"
                    danger-text="This E-mail Address is Invalid!"/>
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <span class="font-semibold p-4">Password</span>
              </td>
              <td>
                <span class="p-4">
                  <vs-input
                    v-model="password"
                    type="password"
                    :danger="invalidPassword"
                    danger-text="Password field can't be empty"/>
                </span>
              </td>
            </tr>
            </tbody>
          </table>
        </vs-col>
      </vs-row>
    </vx-card>
    <permissions @cancel="$emit('cancel')" @saved="savePermissions"/>
  </div>
</template>

<script>
  import Breadcrumb from "../../../components/general/breadcrumb";
  import Permissions from "./permissions";

  export default {
    name: "add-admin",
    components: { Permissions, Breadcrumb },
    data() {
      return {
        name: "",
        email: "",
        password: "",
        permissions: [],
        invalidName: false,
        invalidEmail: false,
        invalidPassword: false
      };
    },
    methods: {
      notifyUser(title, text, color){
        this.$vs.notify({
          title: title,
          text: text,
          color: color
        })
      },
      savePermissions(data){
        this.permissions = data;
        this.saveData();
      },
      validateEmail(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      },
      saveData() {

        if(this.name.length === 0){
          this.invalidName = true;
          this.notifyUser("Error", "Invalid inputs", "danger");
          return;
        }else{
          this.invalidName = false;
        }

        if(!this.validateEmail(this.email)){
          this.invalidEmail = true;
          this.notifyUser("Error", "Invalid inputs", "danger");
          return;
        }else{
          this.invalidEmail = false;
        }

        if(this.password.length === 0){
          this.invalidPassword = true;
          this.notifyUser("Error", "Invalid inputs", "danger");
          return;
        }else{
          this.invalidPassword = false;
        }

        if(this.permissions.length < 1){
          this.notifyUser("Error", "There must be at least 1 permission to proceed!", "danger");
          return;
        }

        let formData = new FormData();

        formData.append("name", this.name);
        formData.append("email", this.email);
        formData.append(`password`, this.password);

        for (let i in this.permissions) {
          if (this.permissions.length > 0) {
            formData.append(`permissions[${i}]`, this.permissions[i]);
          }
        }


        this.$vs.loading();
        this.$httpAdmin
          .post(`/admins`, formData)
          .then(res => {
            console.log(res);
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Success",
              text: "Data is saved successfully",
              color: "success"
            });
            this.$emit("saved");
          })
          .catch((e) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Something Error",
              text: e.response,
              color: "danger"
            });
          });
      }
    }
  };
</script>

<style scoped lang="scss">

</style>
