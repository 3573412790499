<template>
    <div>
        <vx-card>
            <div class="vx-row">
                <div class="vx-col w-full">
                    <div class="flex items-end px-3">
                        <span
                            class="font-medium text-lg leading-none"
                            style="color: #000"
                            >Admin Permissions</span
                        >
                    </div>
                </div>
            </div>

            <div class="block overflow-x-auto">
                <vs-row class="list">
                    <vs-col
                        vs-lg="4"
                        vs-md="12"
                        v-for="(permission, index) in permissions_list"
                        :key="index"
                        class="listItems"
                    >
                        <vs-checkbox
                            v-model="admin_permissions"
                            :vs-value="permission.id"
                            ><span class="cap">{{
                                permission.title.replace(/-/g, " ")
                            }}</span></vs-checkbox
                        >
                    </vs-col>
                </vs-row>
            </div>
            <div class="optionBtns">
                <vs-button
                    icon-pack="feather"
                    icon="icon-save"
                    @click="confirmSaveData"
                    >Save
                </vs-button>
                <vs-button
                    type="border"
                    class="ml-5 mr-10"
                    color="primary"
                    @click="goBack"
                    >Cancel</vs-button
                >
            </div>
        </vx-card>
    </div>
</template>

<script>
import Breadcrumb from "../../../components/general/breadcrumb";

export default {
    name: "permissions",
    components: { Breadcrumb },
    props: {
        permissions: {
            required: false,
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {
            admin_permissions: [],
            permissions_list: [],
        };
    },
    methods: {
        confirmSaveData() {
            this.$vs.dialog({
                title: "Confirm",
                type: "confirm",
                text: "You're going to save these data",
                accept: () => this.$emit("saved", this.admin_permissions),
            });
        },
        goBack() {
            this.$emit("cancel");
        },
        getData() {
            this.$vs.loading();
            this.$httpAdmin
                .get(`/admins/list-permissions`)
                .then((res) => {
                    this.$vs.loading.close();
                    this.permissions_list = res.data.data.sort((a, b) =>
                        a.title > b.title ? 1 : b.title > a.title ? -1 : 0
                    );

                    for (let i in this.permissions_list) {
                        if (this.permissions_list[i].title === "home") {
                            this.admin_permissions.push(
                                this.permissions_list[i].id
                            );
                            break;
                        }
                    }

                    for (let i in this.permissions) {
                        this.admin_permissions.push(this.permissions[i].id);
                    }
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch data from the server",
                        color: "danger",
                    });
                });
        },
    },
    mounted() {
        this.getData();
    },
};
</script>

<style scoped lang="scss">
.list {
    padding: 50px;
}

.listItems {
    padding: 10px;
}

.optionBtns {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.cap {
    text-transform: capitalize !important;
}
</style>
