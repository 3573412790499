<template>
  <div>
    <breadcrumb name="Administration" title="Admin" subtitle="Info" @onTitleClicked="$emit('cancel')"/>
    <vx-card title="Admin Information" class="mb-base">
      <vs-row vs-type="flex">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="12"
          vs-sm="12"
        >
          <vs-row vs-type="flex">
            <vs-col
              vs-type="flex"
              vs-align="center"
              vs-lg="12"
            >
              <span class="font-semibold p-4">Name</span>
              <span class="p-4">
                <vs-input
                  v-model="name"
                  type="text"
                  :danger="invalidName"
                  danger-text="Name field can't be empty!"/>
              </span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-align="center"
              vs-lg="12"
            >
              <span class="font-semibold p-4">Email</span>
              <span class="p-4">
                <vs-input
                  v-model="email"
                  type="email"
                  :danger="invalidEmail"
                  danger-text="This E-mail Address is Invalid!"/>
              </span>
            </vs-col>
            <vs-col
              v-if="!new_password"
              vs-type="flex"
              vs-align="center"
              vs-lg="12"
            >
              <span class="p-4">
                        <vs-button
                          class="bottom-right"
                          icon-pack="feather"
                          icon="icon-edit"
                          @click="new_password = true"
                        >Change Password
                        </vs-button>
              </span>
            </vs-col>
            <vs-divider v-if="new_password"/>
            <vs-col
              v-if="new_password"
              vs-type="flex"
              vs-align="center"
              vs-lg="12"
            >
              <span class="font-semibold p-4">New Password</span>
              <span class="p-4"><vs-input
                v-model="password"
                type="password"
                :danger="invalidPassword"
                danger-text="Password field can't be empty"/></span>
            </vs-col>
            <vs-col
              v-if="new_password"
              vs-type="flex"
              vs-align="center"
              vs-lg="12"
            >
              <span class="p-4">
                        <vs-button
                          class="bottom-right"
                          type="border"
                          @click="new_password = false"
                        >Cancel
                        </vs-button>
              </span>
            </vs-col>
          </vs-row>
        </vs-col>
      </vs-row>
    </vx-card>
    <permissions :permissions="item.permission" @cancel="$emit('cancel')" @saved="savePermissions"/>
  </div>
</template>


<script>
  import Permissions from "./permissions";
  import Breadcrumb from "../../../components/general/breadcrumb";

  export default {
    name: "admin-info",
    components: { Breadcrumb, Permissions },
    props: {
      item: {
        required: true,
        default: () => {
        }
      }
    },
    data() {
      return {
        name: this.item.name,
        email: this.item.email,
        password: "",
        new_password: false,
        invalidName: false,
        invalidEmail: false,
        invalidPassword: false
      };
    },
    methods: {
      notifyUser(title, text, color) {
        this.$vs.notify({
          title: title,
          text: text,
          color: color
        });
      },
      validateEmail(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      },
      savePermissions(data) {
        this.permissions = data;
        this.saveData();
      },
      saveData() {
        if (this.name.length === 0) {
          this.invalidName = true;
          this.notifyUser("Error", "Invalid inputs", "danger");
          return;
        } else {
          this.invalidName = false;
        }

        if (!this.validateEmail(this.email)) {
          this.invalidEmail = true;
          this.notifyUser("Error", "Invalid inputs", "danger");
          return;
        } else {
          this.invalidEmail = false;
        }

        if (this.new_password) {
          if (this.password.length === 0) {
            this.invalidPassword = true;
            this.notifyUser("Error", "Invalid inputs", "danger");
            return;
          } else {
            this.invalidPassword = false;
          }
        }

        if (this.permissions.length < 1) {
          this.notifyUser("Error", "There must be at least 1 permission to proceed!", "danger");
          return;
        }

        let formData = new FormData();

        formData.append("name", this.name);
        formData.append("email", this.email);

        for (let i in this.permissions) {
            formData.append(`permissions[${i}]`, this.permissions[i]);
        }

        if (this.new_password && this.password) {
          formData.append(`password`, this.password);
        }

        this.$vs.loading();
        this.$httpAdmin
          .post(`/admins/${this.item.id}`, formData)
          .then(res => {
            console.log(res);
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Success",
              text: "Data is updated successfully",
              color: "success"
            });
            this.$emit("saved");
          })
          .catch(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Something Error",
              text: "Couldn't update data on the server",
              color: "danger"
            });
          });
      }
    }
  };
</script>

<style scoped lang="scss">
  .switch {
    margin-bottom: 50px;
    float: right;
    padding-right: 45px;
    padding-left: 40px;
  }

  .clr {
    clear: both;
  }

  .product-img {
    height: 110px;
    width: 110px;
    object-fit: cover;
  }

  .optionBtns {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }


  .hidden {
    visibility: hidden;
  }

  .bottom-right {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }


</style>
